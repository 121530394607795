<template>
  <div>
    <BlockUI :blocked="blocked" :fullScreen="true">
      <Card class="main-content">
        <template #content>
          <!-- First Row -->
          <div class="condition-row-wrapper">
            <div style="flex-grow: 9">
              <div class="condition-title">
                Keyword Search
                <i
                  class="pi pi-info-circle"
                  v-tooltip="
                    'Search for multiple keywords, separate words with newline, max for 50 different keywords, only support Amazon.com'
                  "
                ></i>
              </div>
              <div>
                <Textarea
                  v-model="searchState.keywords"
                  class="w-full"
                  inputClass="w-full"
                />
              </div>
            </div>
          </div>

          <div style="margin-top: 1rem; text-align: right">
            <Button class="handleSearch" @click="handleSearch">Search</Button>
          </div>
        </template>
      </Card>

      <Card class="main-content">
        <template #content>
          <ProgressBar
            mode="indeterminate"
            style="height: 0.5em"
            v-if="loading"
          />

          <BlockUI :blocked="loading">
            <DataTable :value="dataSource" responsiveLayout="scroll">
              <Column field="keyword" header="Keyword">
                <template #body="{ data }">
                  <a
                    :href="searchOnAmzUrl(data.keyword)"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {{ data.keyword }}
                  </a>
                </template>
              </Column>
              <Column field="svr" header="SVR">
                <template #body="{ data }">
                  {{ data.sfr }}
                </template>
              </Column>
              <Column field="top_3_asins" header="Top 3 ASINs">
                <template #body="{ data }">
                  <div style="display: flex">
                    <div
                      v-for="asin in data.top_3_asins"
                      :key="asin.Asin"
                      style="padding: 0.5rem"
                    >
                      <div>
                        <a
                          :href="listingUrl(asin.Asin)"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {{ asin.Asin }}
                        </a>
                      </div>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field="trend_graph" header="Trend Graph">
                <template #body="{ data }">
                  <div style="display: flex">
                    <Chart
                      type="line"
                      :data="renderTrendGraph(data.chart_data, 'Sfr')"
                      :options="SVRTrendGraphOptions"
                    />
                    <Chart
                      type="line"
                      :data="
                        renderTrendGraph(
                          data.chart_data,
                          'TotalConversionShare'
                        )
                      "
                      :options="trendGraphOptions('Top3 Conv. Share')"
                    />
                    <Chart
                      type="line"
                      :data="
                        renderTrendGraph(data.chart_data, 'TotalClickShare')
                      "
                      :options="trendGraphOptions('Top3 Click Share')"
                    />
                  </div>
                </template>
              </Column>
              <Column field="tendency" header="Tendency">
                <template #body="{ data }">
                  <div :class="renderTendencyColor(data.tendency)">
                    {{ renderTendency(data.tendency) }}
                  </div>
                </template>
              </Column>
              <Column
                field="compare_to_prev_period"
                header="Compare to Prev. Period"
              >
                <template #body="{ data }">
                  <div :class="renderCompareColor(data.compare_to_prev_period)">
                    {{ renderCompare(data.compare_to_prev_period) }}
                  </div>
                </template>
              </Column>
              <Column
                field="compare_to_last_year"
                header="Compare to Last Year"
              >
                <template #body="{ data }">
                  <div :class="renderCompareColor(data.compare_to_last_year)">
                    {{ renderCompare(data.compare_to_last_year) }}
                  </div>
                </template>
              </Column>
              <Column field="top_3_conv_share" header="Top 3 Conv. Share">
                <template #body="{ data }">
                  {{ sumConversionShare(data.top_3_asins) }}
                </template>
              </Column>
              <Column field="occurrence" header="Occurrence" />
            </DataTable>
          </BlockUI>
        </template>
      </Card>
    </BlockUI>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from "vue";
import { useToast } from "primevue/usetoast";

import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Card from "primevue/card";
import Chart from "primevue/chart";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import ProgressBar from "primevue/progressbar";
import Textarea from "primevue/textarea";

import ProductTrendService from "@/services/product_trend_service";

import { formatNumber } from "@/utils/formatter";

export default defineComponent({
  components: {
    BlockUI,
    Button,
    Card,
    Chart,
    Column,
    DataTable,
    ProgressBar,
    Textarea,
  },

  setup() {
    const toast = useToast();

    const blocked = ref(false);
    const loading = ref(false);
    const searchState = reactive({
      keywords: "",
    });

    const dataSource = ref([]);
    const totalCount = ref(0);

    const handleSearch = () => {
      if (!validateParams()) {
        return;
      }

      loading.value = true;

      // Scroll to Top
      window.scrollTo(0, 0);

      ProductTrendService.multiple_search(searchState)
        .then(({ data }) => {
          totalCount.value = data.total;
          dataSource.value = data.result;
        })
        .catch((e) => {
          console.error(e);
          toast.add({
            severity: "error",
            summary: "Multiple Niche Research",
            detail: "Search fail",
            life: 3000,
          });
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const MAX_SEARCH = 50;
    const validateParams = () => {
      const keywords = searchState.keywords
        .split("\n")
        .map((s) => s.trim())
        .filter((s) => s !== "");
      const uniqKeywords = new Set(keywords);

      if (uniqKeywords.size === 0) {
        toast.add({
          severity: "warn",
          summary: "Multiple Niche Research",
          detail: "You have to enter validate keywords",
          life: 3000,
        });
        return false;
      }

      if (uniqKeywords.size > MAX_SEARCH) {
        toast.add({
          severity: "warn",
          summary: "Multiple Niche Research",
          detail: "Max for 50 different keywords",
          life: 3000,
        });
        return false;
      }

      return true;
    };

    const searchOnAmzUrl = (keyword) => {
      const encodedKeyword = keyword.replaceAll(" ", "+");
      return `https://www.amazon.com/s?k=${encodedKeyword}&ref=nb_sb_noss`;
    };

    const listingUrl = (asin) => {
      return `https://www.amazon.com/dp/${asin}`;
    };

    const renderTendency = (tendency) => {
      if (tendency === null) {
        return "-";
      }

      if (tendency > 0) {
        return `⤴${formatNumber(tendency)}`;
      } else if (tendency < 0) {
        return `⤵${formatNumber(tendency * -1)}`;
      } else {
        return tendency;
      }
    };

    const renderTendencyColor = (tendency) => {
      if (tendency > 0) {
        return "text-green";
      } else if (tendency < 0) {
        return "text-red";
      } else {
        return "text-gray";
      }
    };

    const renderCompare = (diff) => {
      if (diff === null) {
        return "-";
      }

      if (diff > 0) {
        return `↓${formatNumber(diff)}`;
      } else if (diff < 0) {
        return `↑${formatNumber(diff * -1)}`;
      } else {
        return diff;
      }
    };

    const renderCompareColor = (diff) => {
      if (diff === null) {
        return "text-gray";
      }

      if (diff > 0) {
        return "text-red";
      } else if (diff < 0) {
        return "text-green";
      } else {
        return "text-gray";
      }
    };

    const sumConversionShare = (asins) => {
      let sum = asins.reduce((pV, cV) => {
        return pV + cV.ConversionShare;
      }, 0);

      return `${formatNumber(sum)} %`;
    };

    const SVRTrendGraphOptions = {
      plugins: {
        legend: {
          display: false, // hide the legend
        },
        title: {
          display: true,
          text: "SVR",
          position: "bottom",
        },
      },
      scales: {
        y: {
          reverse: true, // reverse y axis
        },
      },
    };

    const trendGraphOptions = (title) => {
      return {
        plugins: {
          legend: {
            display: false, // hide the legend
          },
          title: {
            display: true,
            text: title,
            position: "bottom",
          },
        },
      };
    };

    const renderTrendGraph = (chartData, field) => {
      let lineData = {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: "#42A5F5",
          },
        ],
      };

      chartData.forEach((element) => {
        lineData.labels.push(element.PartitionDate);
        lineData.datasets[0].data.push(element[field]);
      });

      return lineData;
    };

    return {
      blocked,
      loading,
      searchState,
      dataSource,
      totalCount,
      handleSearch,

      searchOnAmzUrl,
      listingUrl,

      renderTendency,
      renderTendencyColor,
      renderCompare,
      renderCompareColor,
      sumConversionShare,

      SVRTrendGraphOptions,
      trendGraphOptions,
      renderTrendGraph,
    };
  },
});
</script>

<style lang="scss" scoped>
.main-content {
  margin-top: 1rem;
}

.condition-row-wrapper {
  display: flex;
}

.condition-title {
  font-weight: 500;
}

.condition-inputnumber {
  ::v-deep .p-inputnumber-input {
    width: 100px;
  }
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}

.text-gray {
  color: gray;
}
</style>
