const REGION_CURRENCY = [
  "",
  "USD",
  "GBP",
  "EUR",
  "JPY",
  "EUR",
  "CAD",
  "MXN",
  "EUR",
  "EUR",
  "INR",
  "EUR",
  "AUD",
  "AED",
];

const REGION_SHORT_NAME = [
  "",
  "US",
  "UK", // or GB
  "DE",
  "JP",
  "FR",
  "CA",
  "MX",
  "ES",
  "IT",
  "IN",
  "NL",
  "AU",
  "AE",
];

const REGION_DOMAIN_TLD = [
  "",
  "com",
  "co.uk",
  "de",
  "co.jp",
  "fr",
  "ca",
  "com.mx",
  "es",
  "it",
  "in",
  "nl",
  "com.au",
  "ae",
];

export const formatNumber = (val) => {
  return new Intl.NumberFormat().format(val);
};

export const formatCurrency = (val, region = 1) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: REGION_CURRENCY[region],
  }).format(val);
};

export const formatPercent = (val, sign = "") => {
  const num = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
  }).format(val);

  return val > 0 ? `${sign}${num}` : num;
};

export const formatShortString = (str, len = "50") => {
  if (str === null) return "";
  return str.length > len ? str.slice(0, len) + "..." : str;
};

export const formatUSDate = (timestamp) => {
  let date = new Date(timestamp);
  let options = { timeZone: "America/Los_Angeles" };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const formatYYYYmmddDate = (date) => {
  let yyyy = date.getFullYear();
  let mm = (date.getMonth() + 1).toString().padStart(2, "0");
  let dd = date.getDate().toString().padStart(2, "0");

  return `${yyyy}${mm}${dd}`;
};

export const toNumberFixed2 = (val) => {
  if (val === null || val === "n/a") {
    return "n/a";
  }

  return formatNumber(val.toFixed(2));
};

export const toCurrencyFixed2 = (val) => {
  if (val === null || val === "n/a") {
    return "n/a";
  }

  return formatCurrency(val.toFixed(2));
};

export const toPercentFixed2 = (val) => {
  if (val === null || val === "n/a") {
    return "n/a";
  }

  return formatPercent(val);
};

export const formatNumberWithoutZero = (val) => {
  if (val === undefined || val == "0" || val == 0) return "--";

  return formatNumber(val);
};

export const formatRegionToShortName = (region) => {
  return REGION_SHORT_NAME[region];
};

export const formatAmazonUrlByRegion = (region) => {
  return `https://www.amazon.${REGION_DOMAIN_TLD[region]}`;
};
